import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/offers`;

export const offerService = {
  sendOffer,
  getSentOffers,
  getReceivedOffers,
  acceptOffer,
  rejectOffer,
  deleteOffer,
};


function sendOffer(
  bookId: string,
  currency: string,
  cashAmount: number,
  offeredBooks: Array<string>
) {
  return fetchWrapper
    .post(`${baseUrl}/send/${bookId}`, {
      currency,
      cashAmount,
      offeredBooks,
    })
    .then((x) => {
      return x;
    });
}

function acceptOffer(offerId: string) {
  return fetchWrapper.get(`${baseUrl}/accept/${offerId}`).then((x) => {
    return x;
  });
}

function rejectOffer(offerId: string) {
  return fetchWrapper.get(`${baseUrl}/decline/${offerId}`).then((x) => {
    return x;
  });
}

function getSentOffers() {
  return fetchWrapper.get(`${baseUrl}/sent`).then((x) => {
    return x;
  });
}

function getReceivedOffers() {
  return fetchWrapper.get(`${baseUrl}/received`).then((x) => {
    return x;
  });
}

function deleteOffer(id: string) {
  return fetchWrapper.delete(`${baseUrl}/delete/${id}`).then((x) => {
    return x;
  });
}
