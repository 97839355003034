import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { PrivateRoute } from "../components";
import { accountService, popupService } from "../services";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Mixpanel } from "helpers/mixpanel-helper";
import SimpleBottomNavigation from "components/navbars/BottomNavbar";
import BootstrapNavbar from "components/navbars/BootstrapNavbar";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AppContext } from "helpers/AppContext";
import { ThreeDots } from "react-loader-spinner";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import AddBooksOnboarding from "components/Onboarding/AddBooksOnboarding";

const SignUp = lazy(() => import("components/registration/SignUp"));
const SocialAuthSuccess = lazy(
  () => import("components/registration/SocialAuthSuccess")
);
const BookShelf = lazy(() => import("components/bookshelf/Bookshelf"));
const Nearby = lazy(() => import("components/nearby/Nearby"));
const AddBooks = lazy(() => import("components/bookshelf/AddBooks"));
const Onboarding = lazy(() => import("components/Onboarding/Onboarding"));
const Chat = lazy(() => import("components/chat/Chat"));
const Offers = lazy(() => import("components/offers/Offers"));
const WentWrong = lazy(() => import("components/modules/WentWrong"));
const ForgotPassword = lazy(
  () => import("components/registration/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("components/registration/ResetPassword")
);
const PrivacyPolicy = lazy(() => import("components/compliance/PrivacyPolicy"));
const DataDeletion = lazy(() => import("components/compliance/DataDeletion"));
const PublicProfile = lazy(() => import("components/profile/PublicProfile"));
const Profile = lazy(() => import("components/profile/Profile"));
const Feedback = lazy(() => import("components/modules/Feedback"));
const VerifyEmail = lazy(() => import("components/registration/VerifyEmail"));
const EditProfile = lazy(() => import("components/profile/EditProfile"));

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "13f16110-32de-4237-8cd5-32746d71939e",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

if (process.env.NODE_ENV === "production") {
  appInsights.loadAppInsights();
}

const App = () => {
  const { pathname } = useLocation();
  const myuser = accountService.userValue;
  const [small, setSmall] = useState(window.innerWidth < 992);

  const [books, setBooks] = useState<any>(null);
  const [collection, setCollection] = useState<any>(null);
  const [wishlist, setWishlist] = useState<any>(null);
  const [sentOffers, setSentOffers] = useState<any>(null);
  const [receivedOffers, setReceivedOffers] = useState<any>(null);
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [newNotifications, setNewNotifications] = useState<boolean>(false);
  const [newChats, setNewChats] = useState<boolean>(false);
  const [activeUsers, setActiveUsers] = useState(0);
  const [signingIn, setSigningIn] = useState<boolean>(true);
  const [signUpForm, setSignUpForm] = useState<boolean>(false);
  const [selectedGenres, setSelectedGenres] = useState<Array<string>>([]);

  // General Dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [popup, setPopup] = useState<any>(null);

  function handleDialogClose() {
    setDialogOpen(false);
    setPopup(null);
    popupService.removeUser(popup._id);
  }
  const value = React.useMemo(
    () => ({
      chats,
      setChats,
      books,
      setBooks,
      page,
      setPage,
      collection,
      setCollection,
      wishlist,
      setWishlist,
      sentOffers,
      setSentOffers,
      receivedOffers,
      setReceivedOffers,
      newChats,
      setNewChats,
      activeUsers,
      setActiveUsers,
      signingIn,
      setSigningIn,
      signUpForm,
      setSignUpForm,
      selectedGenres,
      setSelectedGenres,
      newNotifications,
      setNewNotifications,
    }),
    [
      chats,
      books,
      page,
      collection,
      wishlist,
      sentOffers,
      receivedOffers,
      newChats,
      activeUsers,
      signingIn,
      signUpForm,
      selectedGenres,
      newNotifications,
    ]
  );
  useEffect(() => {
    Mixpanel.track("Website Loaded");

    window.addEventListener("resize", () => {
      setSmall(window.innerWidth < 992);
    });
  }, []);

  return (
    <>
      {myuser && myuser.username && (
        <BootstrapNavbar
          newNotifications={newNotifications}
          setNewNotifications={setNewNotifications}
          newChats={newChats}
          setNewChats={setNewChats}
        />
      )}
      <div
        style={{
          minHeight: `calc(100vh - 70px)`,
          overflowX: "hidden",
        }}
        className={myuser && !myuser.username ? "" : "d-flex"}
      >
        <Suspense
          fallback={
            <div
              style={{ margin: "auto", textAlign: "center", marginTop: "70%" }}
            >
              {/* <ThreeDots
                height={50}
                width={50}
                ariaLabel="three-dots-loading"
                color="#3D79CA"
                visible={true}
              /> */}
            </div>
          }
        >
          <AppContext.Provider value={value}>
            <Switch>
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <PrivateRoute exact path="/" component={SignUp} />
              <PrivateRoute
                exact
                path="/onboarding-add-books"
                component={AddBooksOnboarding}
              />
              <PrivateRoute exact path="/bookshelf" component={BookShelf} />
              <PrivateRoute exact path="/nearby" component={Nearby} />
              <Route path="/policy" component={PrivacyPolicy} />
              <Route path="/deletion" component={DataDeletion} />
              <Route path="/profile">
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute
                  exact
                  path="/profile/edit"
                  component={EditProfile}
                ></PrivateRoute>
              </Route>
              <Route path={`/forgot-password`} component={ForgotPassword} />
              <Route
                path={`/reset-password`}
                render={() => (
                  <>
                    <BootstrapNavbar
                      newNotifications={newNotifications}
                      setNewNotifications={setNewNotifications}
                      newChats={newChats}
                      setNewChats={setNewChats}
                    />
                    <ResetPassword />
                  </>
                )}
              />
              <Route
                path={`/users/verify-email`}
                render={() => (
                  <>
                    <BootstrapNavbar
                      newNotifications={newNotifications}
                      setNewNotifications={setNewNotifications}
                      newChats={newChats}
                      setNewChats={setNewChats}
                    />
                    <VerifyEmail />
                  </>
                )}
              />
              <PrivateRoute exact path="/chat" component={Chat} />
              <PrivateRoute exact path="/offers" component={Offers} />
              <PrivateRoute exact path="/add" component={AddBooks} />
              <Route path="/onboarding">
                <PrivateRoute exact path="/onboarding" component={Onboarding} />
                <PrivateRoute
                  exact
                  path="/onboarding/addBooks"
                  component={AddBooks}
                />
              </Route>
              <PrivateRoute exact path="/wentWrong" component={WentWrong} />
              <PrivateRoute exact path="/feedback" component={Feedback} />
              <Route
                exact
                path="/socialauth/success"
                component={SocialAuthSuccess}
              />
              <Route path="/account" component={SignUp} />
              <Route path="*" component={PublicProfile} />
            </Switch>
          </AppContext.Provider>
        </Suspense>
      </div>
      {small && myuser && myuser.username && (
        <SimpleBottomNavigation
          newNotifications={newNotifications}
          setNewNotifications={setNewNotifications}
        />
      )}
      {popup && (
        <Dialog onClose={handleDialogClose} open={dialogOpen}>
          <DialogTitle>{popup.title}</DialogTitle>
          <DialogContent>{popup.body}</DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default process.env.NODE_ENV === "production"
  ? withAITracking(reactPlugin, App)
  : App;
