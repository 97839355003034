import { Route, Redirect, RouteProps } from "react-router-dom";
import { accountService } from "../services";



interface PrivateRouteProps extends RouteProps {
  roles?: string[];
}

function PrivateRoute({
  component: Component,
  roles,
  ...rest
}: PrivateRouteProps) {
 
  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        const user = accountService.userValue;

        if (user && user.username) {
          if (props.location.pathname === "/") {
            return (
              <Redirect
                to={{
                  pathname: "/nearby",
                  state: { from: props.location },
                }}
              />
            );
          } else return <Component {...props} />;
        } else if (user) {
          if (props.location.pathname !== "/onboarding") {
            return (
              <Redirect
                to={{
                  pathname: "/onboarding",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        } else {
          // not logged in so redirect to signin page with the return url
          return (
            <Redirect
              to={{
                pathname: "/account",
                state: { from: props.location },
              }}
            />
          );
        }

      }}
    />
  );
}

export { PrivateRoute };
