import { useState, useEffect } from "react";

import { HeaderSection } from "./CommonModules";

import ContainerButtons from "./ContainerButtons";
import { Button as MUIButton, Grid } from "@mui/material";
import ResponsiveAppBar from "components/modules/ResponsiveAppBar";

export default function AddBooksOnboarding({
  onNextButtonClick,
  onPreviousButtonClick,
}: {
  onNextButtonClick: () => any;
  onPreviousButtonClick: () => void;
}) {
  const [small, setSmall] = useState<boolean>(window.innerWidth < 768);
  const [openLoader, setOpenLoader] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSmall(window.innerWidth < 768);
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <ResponsiveAppBar />
      <HeaderSection step="3" title="Add Books" />

      <Grid
        container
        flex={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingRight: {
            xs: 4,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0,
          },
        }}
      >
        <Grid item xs={12} md={8}>
          <ContainerButtons
            onNextButtonClick={onNextButtonClick}
            setOpenLoader={setOpenLoader}
          />
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection={"row"}
        sx={{ padding: "1em" }}
        justifyContent={"space-between"}
      >
        <MUIButton
          variant="outlined"
          onClick={onPreviousButtonClick}
          sx={{
            color: "black",
            borderColor: "skyblue",
            ":hover": {
              borderColor: "skyblue",
              color: "black",
            },
          }}
        >
          Previous
        </MUIButton>

        <MUIButton
          onClick={async () => {
            setOpenLoader(true);
            await onNextButtonClick();
            window.location.href = "/";
          }}
          sx={{
            color: "white",
            cursor: "pointer",
            width: 100,

            backgroundColor: "#66CC7E",
            ":hover": {
              backgroundColor: "#66CC7E",
            },
          }}
        >
          Skip
        </MUIButton>
      </Grid>
    </div>
  );
}
