import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

import FileSaver from "file-saver";

const baseUrl = `${config.apiUrl}/collections`;

export const collectionService = {
  downloadBooks,
  getCollection,
  getAll,
  removeFromCollection,
  addToCollection,
  updateCollection,
  getLandingBooks,
  getGenres,
};

function downloadBooks() {
  return fetchWrapper
    .download(`${baseUrl}/getcsv`)
    .then(function (response) {
      console.log(response);
      return response.blob();
    })
    .then(function (blob) {
      FileSaver.saveAs(blob, "collection.csv");
    });
}

function getGenres() {
  return fetchWrapper.get(`${baseUrl}/genres`);
}

function updateCollection(body: any, bookId: string) {
  return fetchWrapper.put(`${baseUrl}/update/${bookId}`, body).then((x) => {
    return x;
  });
}

function getCollection(userId: string, region = {}) {
  return fetchWrapper
    .get(`${baseUrl}/get/?region=${JSON.stringify(region)}&userId=${userId}`)
    .then((x) => {
      return x;
    });
}

function removeFromCollection(bookId: string) {
  return fetchWrapper.delete(`${baseUrl}/${bookId}`).then((x) => {
    return x;
  });
}

function addToCollection(body: any) {
  console.log("body", body);
  return fetchWrapper.post(`${baseUrl}/add`, body).then((x) => {
    return x;
  });
}

async function getLandingBooks(
  region: {
    name: any;
    countryCode: any;
    stateCode: any;
    latitude: any;
    longitude: any;
  },
  query: string,
  page: number,
  limit: number | null,
  booksDistance = 100,
  sorting: string | null = null

) {

  console.log("region", region , "query", query, "page", page, "limit", limit, "booksDistance", booksDistance, "sorting", sorting);
  return fetchWrapper
    .get(
      `${baseUrl}/getlandingbooks?region=${JSON.stringify(
        region
      )}&page=${page.toString()}&limit=${
        limit ? limit.toString() : limit
      }&search=${query}&distance=${booksDistance.toString()}&sorting=${sorting}`
    )
    .then((x) => {
      
    
      return x;
    }).catch((err) => {
      console.log ("err: from collection.service" ,  err);
    });
}

async function getAll(
  region: {
    name: any;
    countryCode: any;
    stateCode: any;
    latitude: any;
    longitude: any;
  },
  query: string,
  page: number,
  limit: number | null,
  genres: Array<string>,
  booksDistance = 100,
  sorting: string | null = null
) {
  return fetchWrapper
    .get(
      `${baseUrl}/getall?region=${JSON.stringify(
        region
      )}&page=${page.toString()}&limit=${
        limit ? limit.toString() : limit
      }&search=${query}&distance=${booksDistance.toString()}&genres=${genres}&sorting=${sorting}`
    )
    .then((x) => {
      return x;
    });
}
