import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/popups`;

export const popupService = {
  fetchPopup,
  removeUser,
};

function fetchPopup() {
  return fetchWrapper.get(baseUrl);
}

function removeUser(popupId: string) {
  return fetchWrapper.delete(`${baseUrl}/${popupId}`);
}
