import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Paper } from "@mui/material";
import { ImBooks } from "react-icons/im";
import { BsChat } from "react-icons/bs";
import { RiCompassLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import { accountService } from "services";
import { useEffect, useState } from "react";

export default function SimpleBottomNavigation({
  newNotifications,
  setNewNotifications,
}: {
  newNotifications: boolean;
  setNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const user = accountService.userValue;
  let history = useHistory();
  const location = useLocation();

  const [value, setValue] = useState(() => {
    if (location.pathname === "/") {
      return 1;
    } else if (location.pathname === "/chat") {
      return 0;
    } else {
      return 2;
    }
  });

  useEffect(() => {
    const getCurrentValue = () => {
      if (location.pathname === "/chat") {
        return 0;
      } else if (location.pathname === "/bookshelf") {
        return 2;
      } else {
        return 1;
      }
    };

    setValue(getCurrentValue());

    const notificationInterval = setInterval(async () => {
      const response = await accountService.getNewNotifications();
      setNewNotifications(response.notificationFlag);
    }, 5000);

    return () => {
      clearInterval(notificationInterval);
    };
  }, [location.pathname, setNewNotifications]);

  if (!user) return null;

  if (!user.username) return null;
  console.log("bottom nav value", value);
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={
          location.pathname === "/nearby" ||
          location.pathname === "/chat" ||
          location.pathname === "/bookshelf"
            ? value
            : 3
        }
        onChange={(_, newValue) => {
          setValue(newValue);
          console.log("bottom nav new", newValue);
          switch (newValue) {
            case 0:
              history.push("/chat");
              return;
            case 2:
              history.push("/bookshelf");
              return;
            default:
              history.push("/nearby");
              return;
          }
        }}
        sx={{
          bgcolor: "white",

          "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
            color: "#6B6468",
            fontWeight: 600,
            fontFamily: "Popins , sans-serif",
          },

          "& .Mui-selected .MuiSvgIcon-root, & .Mui-selected .MuiBottomNavigationAction-label":
            {
              color: "#4B76C3",
              fontWeight: 600,
            },
          height: 60,
        }}
      >
        <BottomNavigationAction
          onClick={() => {
            accountService.noNewNotification();
            setNewNotifications(false);
          }}
          sx={{}}
          label="Chat"
          icon={
            <div style={{ position: "relative" }}>
              <div
                style={{
                  padding: "0.1em 1em 0.25em 1em",
                  marginTop: "0.1em",
                }}
              >
                <BsChat size={20} />
              </div>
              {newNotifications && (
                <div
                  style={{
                    top: "6px",
                    right: 6,
                    position: "absolute",
                    background: "red",
                    borderRadius: "50%",
                    width: 10,
                    height: 10,
                  }}
                />
              )}
            </div>
          }
        />
        <BottomNavigationAction
          sx={{}}
          label="Buy"
          icon={
            <div
              style={{
                padding: "0.1em 1em 0.25em 1em",
                marginTop: "0.1em",
              }}
            >
              <RiCompassLine size={23} />
            </div>
          }
        />
        <BottomNavigationAction
          sx={{}}
          label="Sell"
          icon={
            <div
              style={{
                padding: "0.1em 1em 0.25em 1em",
                marginTop: "0.1em",
              }}
            >
              <ImBooks size={20} />
            </div>
          }
        />
      </BottomNavigation>
    </Paper>
  );
}
