import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/feedbacks`;

export const feedbackService = { submitFeedback };

async function submitFeedback(rating: number, suggestion: string) {
  return fetchWrapper.post(`${baseUrl}/submit`, { rating, suggestion });
}
