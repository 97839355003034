// @ts-ignore

import placeholder from "../assets/placeholder.png";

export function truncatedText(title: string, chars: number) {
  if (title.length <= chars) return title;

  return title.substring(0, chars) + "...";
}

export function addDefaultSrc(ev: any) {
  ev.target.src = placeholder;
}

export function getLocality(address: Array<any>) {
  for (let i = 0; i < address.length; i++) {
    if (address[i].types.includes("locality")) {
      return address[i].long_name;
    }
  }
}

export function getStateCode(address: Array<any>) {
  for (let i = 0; i < address.length; i++) {
    if (address[i].types.includes("administrative_area_level_1")) {
      return address[i].short_name;
    }
  }
}

export function getCountryCode(address: Array<any>) {
  for (let i = 0; i < address.length; i++) {
    if (address[i].types.includes("country")) {
      return address[i].short_name;
    }
  }
}
