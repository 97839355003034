import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./index.css";
import "css/loadingAnimation.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import { accountService } from "services";

import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";

import { createTheme, ThemeProvider, CircularProgress } from "@mui/material";
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

// attempt silent token refresh before startup
const theme = createTheme({
  typography: {
    fontFamily: "Poppins , sans-serif",
  },
});
let tokenexpiry = new Date(parseInt(localStorage.getItem("tokenexpiry") ?? ""));
let nowDate = new Date();

if (nowDate.getTime() - tokenexpiry.getTime() >= 0) {
  ReactDOM.render(
    <div className="text-center" style={{ marginTop: "40vh" }}>
      <CircularProgress />
    </div>,
    document.getElementById("root")
  );
  accountService.refreshToken().finally(startApp);
} else startApp();

function startApp() {
  ReactDOM.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
