import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Mixpanel } from "helpers/mixpanel-helper";

import bookfishLogoTitle from "../../assets/LogoWhiteBack.png";
import { Button } from "@mui/material";
import { FaRegUserCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const ResponsiveAppBar = ({
  setSigningIn,
  setSignUpForm,
  signUpForm = false,
}: {
  setSigningIn?: (signingIn: boolean) => void;
  setSignUpForm?: (signUpForm: boolean) => void;
  signUpForm?: boolean;
}) => {
  let history = useHistory();
  return (
    <AppBar elevation={0} sx={{ background: "#FAFBFC" }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <img
              onClick={() => {
                if (setSigningIn) setSigningIn(true);
                if (setSignUpForm) setSignUpForm(false);
                history.push("/");
              }}
              style={{ height: 20, cursor: "pointer" }}
              src={bookfishLogoTitle}
              alt="logo"
            />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <img
              onClick={() => {
                if (setSigningIn) setSigningIn(true);
                if (setSignUpForm) setSignUpForm(false);
                history.push("/");
              }}
              style={{ height: 30, cursor: "pointer" }}
              src={bookfishLogoTitle}
              alt="logo"
            />
          </Box>
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none" },
              marginTop: 2,
              marginLeft: 4,
              fontWeight: "bold",
              color: "black",
            }}
          >
            <Typography sx={{ fontSize: 15 }}>
              Connecting the world, one book at a time!
            </Typography>
          </Box>

          {!signUpForm && (
            <Box sx={{ flexGrow: 0 }}>
              <Button
                sx={{
                  borderRadius: 3,
                  textTransform: "none",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#4B76C3",
                  borderColor: "#4B76C3",
                }}
                onClick={() => {
                  Mixpanel.track("Sign In Clicked");
                  if (setSigningIn) setSigningIn(true);
                  if (setSignUpForm) setSignUpForm(true);
                  history.push("/");
                }}
                variant="outlined"
                endIcon={<FaRegUserCircle />}
              >
                Sign In
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
