const prod = Object.freeze({
  apiUrl: process.env.REACT_APP_API_URL ?? "NO_URL_IN_CONFIG",
});

const dev = Object.freeze({
  // apiUrl: "https://apiv2.bookfish.in",
  apiUrl: "http://localhost:7071",
});

export const config = process.env.NODE_ENV === "development" ? dev : prod;
// export const config =  dev ;
