import { useState, useEffect, useRef } from "react";
import "css/navbar.css";

import { FaRegHandshake } from "react-icons/fa";

import { IconButton } from "@mui/material";

import parse from "html-react-parser";
import { accountService, notificationService } from "../../services";

import { Overlay, Popover, Row, Col } from "react-bootstrap";
import { useHistory, useLocation, NavLink } from "react-router-dom";

import { GoPrimitiveDot } from "react-icons/go";

import placeholder from "../../assets/placeholder.png";

import { Mixpanel } from "helpers/mixpanel-helper";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";

import Avatar from "@mui/material/Avatar";

import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import logoWithTitle from "../../assets/logoWithTitle.png";
import NotificationsIcon from "@mui/icons-material/Notifications";

const BootstrapNavbar = ({
  newNotifications,
  setNewNotifications,
  newChats,
  setNewChats,
}: {
  newNotifications: boolean;
  setNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  newChats: boolean;
  setNewChats: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [notifications, setNotifications] = useState<Array<any>>([]);

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showProfilePopover, setShowProfilePopover] = useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const user = accountService.userValue;

  let history = useHistory();
  let location = useLocation();

  const target = useRef(null);

  function setItemTouched(id: string) {
    const updatedNotificationsList = notifications;
    updatedNotificationsList.find((item) => item._id === id).touched = "true";
    setNotifications(updatedNotificationsList);
    notificationService.setNotificationTouched(id);
  }

  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        const myNotifications = await notificationService.getMyNotifications();
        setNotifications(myNotifications);
        const notificationFlag = await accountService.getNewNotifications();
        setNewNotifications(notificationFlag.message);
        const newChats = await accountService.getNewChats();
        setNewChats(newChats.message);
      }, 5000);
    })();
    document.body.addEventListener(
      "click",
      (e) => {
        let ignoreClickOnMeEvent = document.getElementById(
          "theOneTheOnlyTheLegendTheNavbar"
        );
        if (!ignoreClickOnMeEvent?.contains(e.target as Node))
          setExpanded(false);
        let ignoreClickNotificationBell = document.getElementById(
          "theOneTheOnlyNotificationBell"
        );
        let ignoreClickNotificationPopover = document.getElementById(
          "theOneTheOnlyNotificationPopover"
        );
        if (
          !ignoreClickNotificationBell?.contains(e.target as Node) &&
          !ignoreClickNotificationPopover?.contains(e.target as Node)
        )
          setShowPopover(false);

        let ignoreClickProfileBell = document.getElementById(
          "theOneTheOnlyProfileBell"
        );
        let ignoreClickProfilePopover = document.getElementById(
          "theOneTheOnlyProfilePopover"
        );
        if (
          !ignoreClickProfileBell?.contains(e.target as Node) &&
          !ignoreClickProfilePopover?.contains(e.target as Node)
        )
          setShowProfilePopover(false);
      },
      true
    );
  }, [setNewChats, setNewNotifications]);

  useEffect(() => {
    if (showPopover && !notifications.length) {
      setTimeout(() => {
        setShowPopover(false);
      }, 3000);
    }
  }, [showPopover, notifications.length]);

  useEffect(() => {
    setExpanded(false);
    setShowPopover(false);
    setShowProfilePopover(false);
  }, [location]);

  useEffect(() => {
    if (notifications.length === 0) {
      setShowPopover(false);
    }
  }, [notifications.length]);

  if (!user) return null;

  if (!user.username) return null;

  // material ui

  const settings = ["Profile", "Feedback", "Logout"];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: "white",
        boxShadow: "none",
        borderBottom: "1px solid #C9C9C9",
        "&$active": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Toolbar>
        <Box sx={{ marginLeft: { xs: 0, md: 2 } }}>
          <Box
            component="img"
            src={logoWithTitle}
            sx={{
              display: { xs: "none", md: "flex" },

              // letterSpacing: ".3rem",
              width: 192,
              height: 28,
              cursor: "pointer",
              // marginLeft: { xs: 10, md: 0 },
            }}
            onClick={() => window.open("/", "_self")}
          />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "inline", md: "none" } }}>
          <Box
            onClick={() => {
              window.open("/", "_self");
            }}
            component="img"
            src={logoWithTitle}
            sx={{
              cursor: "pointer",
              // width: 50,
              height: 20,
            }}
            alt=""
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            marginLeft: 10,
          }}
        >
          <NavLink
            to="/bookshelf"
            style={{
              textDecoration: "none",
              color: location.pathname === "/bookshelf" ? "#000" : "#6B6468",

              fontWeight: 400,
            }}
          >
            Sell
          </NavLink>
          <NavLink
            onClick={() => {
              window.open("/", "_self");
            }}
            to="/"
            style={{
              textDecoration: "none",
              color: location.pathname === "/nearby" ? "#000" : "#6B6468",
              marginLeft: 44,

              fontWeight: 400,
            }}
          >
            Buy
          </NavLink>
          <NavLink
            to="/chat"
            style={{
              textDecoration: "none",
              color: location.pathname === "/chat" ? "#000" : "#6B6468",
              marginLeft: 44,
              fontWeight: 400,
            }}
          >
            Inbox
          </NavLink>
        </Box>

        <Box sx={{ marginRight: { xs: 0, md: 2 }, boxShadow: "none" }}>
          <IconButton
            sx={{
              mx: { xs: 0, md: 2 },
            }}
          >
            <FaRegHandshake
              onClick={() => {
                accountService.noNewNotification();
                setNewNotifications(false);
                history.push("/offers");
              }}
            />

            {newNotifications && (
              <div
                style={{
                  top: 10,
                  right: 5,
                  position: "absolute",
                  background: "red",
                  borderRadius: "50%",
                  width: 10,
                  height: 10,
                }}
              />
            )}
          </IconButton>

          <IconButton
            sx={{
              marginRight: { xs: 0, md: 2 },
              // "&:hover": {
              //   boxShadow: "none",
              //   // background: "red",
              //   backgroundColor: "transparent",
              // },
            }}
          >
            <NotificationsIcon
              ref={target}
              onClick={() => {
                Mixpanel.track("Notifications Popup Opened");
                accountService.noNewNotification();
                setNewNotifications(false);
                setShowPopover(!showPopover);
              }}
            />
            <div style={{ position: "relative" }}>
              {newNotifications && (
                <div
                  style={{
                    top: -10,
                    right: 2,
                    position: "absolute",
                    background: "red",
                    borderRadius: "50%",
                    width: 10,
                    height: 10,
                  }}
                />
              )}
            </div>

            <Overlay
              target={target.current}
              show={showPopover}
              placement="bottom"
              rootClose
              rootCloseEvent="click"
            >
              <Popover id="theOneTheOnlyNotificationPopover">
                <Popover.Header as="h3">Your Notifications</Popover.Header>
                <Popover.Body
                  style={{
                    padding: "0px",
                    margin: "0px",
                    maxHeight: 300,
                    overflow: "scroll",
                  }}
                >
                  {notifications.length ? (
                    <>
                      {notifications.map((item: any, idx: number) => (
                        <div
                          style={{
                            padding: "10px",
                            paddingBottom: "0px",
                            margin: "0px",
                            borderBottom:
                              idx === notifications.length - 1
                                ? "0px"
                                : "1px solid black",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            let ignoreClickOnMeEvents =
                              document.getElementsByClassName(
                                "theOneTheOnlyNotificationRemoveButton"
                              );
                            let contains = false;
                            if (ignoreClickOnMeEvents.length) {
                              for (
                                let i = 0;
                                i < ignoreClickOnMeEvents.length;
                                i++
                              ) {
                                if (
                                  ignoreClickOnMeEvents[i]?.contains(
                                    e.target as Node
                                  )
                                )
                                  contains = true;
                              }
                            }
                            if (!contains) {
                              setItemTouched(item._id);
                              if (item.type === "Offer Received") {
                                history.push("/offers");
                              } else {
                                window.open(item.link, "_self");
                              }
                            }
                          }}
                          key={item._id}
                          id={`myNotifications_${item._id}`}
                          className="myNotifications"
                        >
                          <Row
                            style={{
                              width: "100%",
                            }}
                          >
                            <Col xs={11}>{parse(item.message)}</Col>
                            <Col
                              xs={1}
                              style={{ padding: "0px", margin: "0px" }}
                            >
                              <IconButton
                                style={{
                                  position: "relative",
                                  bottom: "9px",
                                  left: "8px",
                                  color: "blue",
                                  marginTop: 15,
                                }}
                                aria-label="clear-notification"
                                size="small"
                                className="theOneTheOnlyNotificationRemoveButton"
                                // onClick={() => onRemoveNotification(item._id)}
                              >
                                {item.touched ? null : <GoPrimitiveDot />}
                              </IconButton>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div style={{ padding: "20px", textAlign: "center" }}>
                      No new notifications.
                    </div>
                  )}
                </Popover.Body>
              </Popover>
            </Overlay>
          </IconButton>

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{}}>
              <Avatar
                alt="Remy Sharp"
                src={
                  !user.profilePic
                    ? placeholder
                    : user.profilePic +
                      "?" +
                      Math.floor(Math.random() * 9000 + 1000)
                }
                sx={{ width: 32, height: 32 }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  if (setting === "Logout") {
                    accountService.logout();
                    window.open("/", "_self");
                  } else {
                    handleCloseUserMenu();
                    history.push(`/${setting.toLowerCase()}`);
                  }
                }}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BootstrapNavbar;
