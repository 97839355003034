import "react-phone-number-input/style.css";

import { useState } from "react";

import { TextField, Typography } from "@mui/material";

export function UsernameInputField({
  formik,
  styleClasses,
}: {
  formik: any;
  styleClasses: any;
}) {
  return (
    <>
      <TextField
        sx={{ width: "100%" }}
        {...formik.getFieldProps("username")}
        id="username"
        label="Username"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: 20,
          },
        }}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
      />
      {/* <TextField
        {...formik.getFieldProps("username")}
        // className={styleClasses.InputField}
        style={{
          width: "100%",

          border: "1px solid #E5E4E3",
          boxShadow: "none",

          borderRadius: 16,
          color: "#000",
          marginTop: 10,
          padding: "5px 10px",
        }}
        autoCapitalize="none"
        type="text"
        name="username"
        id="username"
        placeholder="Username*"
        isInvalid={formik.touched.username && !!formik.errors.username}
        autoFocus
        width={100}
        defaultValue={formik.values.username}
      /> */}
      {formik.touched.username && formik.errors.username && (
        <div className="invalid-feedback" style={{ textAlign: "center" }}>
          {formik.errors.username}
        </div>
      )}
    </>
  );
}

export function FullNameInputField({
  formik,
  styleClasses,
}: {
  formik: any;
  styleClasses: any;
}) {
  return (
    <>
      <TextField
        sx={{ width: "100%" }}
        {...formik.getFieldProps("fullName")}
        id="fullName"
        label="Full Name"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: 20,
          },
        }}
        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
        helperText={formik.touched.fullName && formik.errors.fullName}
      />
      {formik.touched.fullName && formik.errors.fullName && (
        <div className="invalid-feedback" style={{ textAlign: "center" }}>
          {formik.errors.fullName}
        </div>
      )}
    </>
  );
}

export function BioInputField({ formik }: { formik: any }) {
  const [count, setCount] = useState(0);

  return (
    <>
      <TextField
        {...formik.getFieldProps("bio")}
        sx={{ width: "100%" }}
        rows={4}
        multiline
        id="bio"
        label="Bio"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: 20,
          },
        }}
        error={formik.touched.bio && Boolean(formik.errors.bio)}
        helperText={formik.touched.bio && formik.errors.bio}
      />

      <p
        className="text-end"
        style={{ color: count > 240 ? "red" : "", fontSize: 12 }}
      >
        {count}/240
      </p>
      {formik.touched.bio && formik.errors.bio && (
        <div className="invalid-feedback" style={{ textAlign: "center" }}>
          {formik.errors.bio}
        </div>
      )}
    </>
  );
}

export function AgeInputField({
  formik,
  styleClasses,
}: {
  formik: any;
  styleClasses: any;
}) {
  return (
    <>
      <TextField
        {...formik.getFieldProps("age")}
        id="age"
        label="Age"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: 20,
          },
        }}
        error={formik.touched.age && Boolean(formik.errors.age)}
        helperText={formik.touched.age && formik.errors.age}
      />
      {formik.touched.age && formik.errors.age && (
        <div className="invalid-feedback" style={{ textAlign: "center" }}>
          {formik.errors.age}
        </div>
      )}
    </>
  );
}

export function HeaderSection({
  step,
  title,
}: {
  step: string;
  title: string;
}) {
  return (
    <div style={{ textAlign: "start", paddingLeft: 20 }}>
      <Typography style={{ marginTop: 10, fontSize: 15 }}>
        Step {step}/2
      </Typography>
      <Typography style={{ color: "#F2C79B", fontWeight: 600, fontSize: 20 }}>
        {title}
      </Typography>
    </div>
  );
}
