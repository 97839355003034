const Constants = Object.freeze({
  genres: [
    "Fiction",
    "Non Fiction",
    "Classic",
    "Comic Book",
    "Graphic Novel",
    "Mystery",
    "Detective",
    "Fantasy",
    "Historical Fiction",
    "Horror",
    "Literary Fiction",
    "Romance",
    "Science Fiction (Sci-Fi)",
    "Short Story",
    "Suspense",
    "Thriller",
    "Biography",
    "Autobiography",
    "Cookbook",
    "History",
    "Poetry",
    "Self-Help",
    "True Crime",
    "Crime",
  ],
  bestSellingBooks: [
    "TadlzgEACAAJ",
    "wrOQLV6xB-wC",
    "0g0BUZQJ368C",
    "YljRDwAAQBAJ",
    "ivzfRJGrdFsC",
    "L2EQuwEACAAJ",
    "mKfDFa8r3pYC",
    "XeMinwEACAAJ",
    "mEx4BwAAQBAJ",
    "S8SgLul3n7gC",
  ],
});

export default Constants;
