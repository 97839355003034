import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/notifications`;

export const notificationService = {
  getMyNotifications,
  deleteNotification,
  setNotificationTouched,
};

async function setNotificationTouched(notificationId: string) {
  return fetchWrapper.get(
    `${baseUrl}/setNotificationTouched/${notificationId}`
  );
}

async function getMyNotifications() {
  return fetchWrapper.get(`${baseUrl}/myNotifications`);
}

async function deleteNotification(notificationId: string) {
  return fetchWrapper.delete(`${baseUrl}/deleteNotification/${notificationId}`);
}
